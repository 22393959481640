<template>
  <v-dialog
    v-model="show"
    max-width="800px"
  >
    <v-card class="pa-6 pa-sm-12 secondary">
      <v-row justify="end">
        <v-col
          class="pa-0"
          cols="1"
        >
          <base-btn
            height="32"
            width="32"
            min-width="0"
            icon
            rounded
            @click="show = false"
          >
            <base-img
              :src="require('@/assets/icons/icon-close.png')"
              height="32"
              width="32"
              contain
            />
          </base-btn>
        </v-col>
      </v-row>

      <v-container>
        <v-carousel
          :cycle="true"
          :height="iCarouselHeight"
          hide-delimiter-background
          show-arrows
        >
          <v-carousel-item
            v-for="(image, i) in m_arrImgPathsPhoto"
            :key="i"
          >
            <base-img
              :src="require(`@/assets/photos/${image}`)"
              :height="iCarouselHeight * 0.85"
              contain
            />
          </v-carousel-item>
        </v-carousel>
      </v-container>

      <component
        :is="m_objTitle.strComponent"
        :v-if="m_objTitle"
        :class="getTextClasses(m_objTitle.eType, ['black--text', 'text-center'])"
      >
        {{ m_objTitle.strText }}
      </component>

      <div
        v-if="m_objSubtitles.arrText.length > 0"
      >
        <component
          :is="m_objSubtitles.strComponent"
          v-for="(text, i) in m_objSubtitles.arrText"
          :key="i"
          :class="getTextClasses(m_objSubtitles.eType, ['black--text', 'text-center','mb-6'])"
        >
          {{ text }}
        </component>
      </div>

      <v-row
        justify="space-between"
      >
        <v-col
          class="pa-0 pa-sm-3"
          cols="12"
          sm="6"
        >
          <component
            :is="m_objDetails.strComponent"
            :v-if="m_objDetails.strDuration"
            :class="getTextClasses(m_objDetails.eType, ['black--text', 'text-left', 'd-flex flex-row', 'align-center'])"
          >
            <base-img
              :src="require('@/assets/icons/icon-clock.png')"
              class="d-inline-block vertical-align-bottom mr-3 square-32"
              contain
            />
            {{ m_objDetails.strDuration }}
          </component>

          <component
            :is="m_objDetails.strComponent"
            :v-if="m_objDetails.strMembers"
            :class="getTextClasses(m_objDetails.eType, ['black--text', 'text-left', 'd-flex flex-row', 'align-center'])"
          >
            <base-img
              :src="require('@/assets/icons/icon-people.png')"
              class="d-inline-block vertical-align-bottom mr-3 square-32"
              contain
            />
            {{ m_objDetails.strMembers }}
          </component>
        </v-col>

        <v-col
          class="pa-0 pa-sm-3"
          cols="12"
          sm="5"
        >
          <component
            :is="m_objDetails.strComponent"
            :v-if="m_objDetails.strTechstacks"
            :class="getTextClasses(m_objDetails.eType, ['black--text', 'text-left', 'd-flex flex-row', 'align-center'])"
          >
            <base-img
              :src="require('@/assets/icons/icon-tech-idea.png')"
              class="d-inline-block vertical-align-bottom mr-3 square-32"
              contain
            />
            {{ m_objDetails.strTechstacks }}
          </component>

          <div
            class="1-100 text-right"
          >
            <base-img
              v-for="(logo, i) in m_objDetails.arrTechImgs"
              :key="i"
              :src="require(`@/assets/logos/tech/${logo}`)"
              class="d-inline-block vertical-align-top mx-1"
              height="48"
              width="48"
              contain
            />
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
  import TextClass from '@/mixins/text-class'

  export default {
    name: 'KDAPortfolioModal',

    mixins: [TextClass],

    props: {
      value: Boolean,
      textColor: {
        type: String,
        default: 'grey',
      },
      objProject: {
        type: Object,
        required: true,
      },
    },

    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          const objShow = {
            key: this.$vnode.key,
            bShow: value,
          }
          this.$emit('showModal', objShow)
          this.$emit('input', value)
        },
      },
      iCarouselHeight () {
        return 300
      },
      m_arrImgPathsPhoto () {
        return this.objProject.arrImgPathsPhoto
      },
      m_objTitle () {
        return {
          strText: this.objProject.strName,
          strComponent: 'p',
          eType: this.eContentType.TITLE_3,
        }
      },
      m_objSubtitles () {
        return {
          arrText: this.objProject.arrLongDescription,
          strComponent: 'p',
          eType: this.eContentType.SUBTITLE_3,
        }
      },
      m_objDetails () {
        return {
          strDuration: this.objProject.strProjectDuration,
          strMembers: this.objProject.strProjectMembers,
          strTechstacks: this.objProject.arrTechstacks.join(', '),
          arrTechImgs: this.objProject.arrTechImgs,
          strComponent: 'p',
          eType: this.eContentType.SUBTITLE_1,
        }
      },
    },
  }
</script>

<style lang="sass">
  .square-32
    width: 32px !important
    height: 32px !important
    max-width: 32px !important
    max-height: 32px !important

  .vertical-align-middle
    vertical-align: middle !important

  .vertical-align-bottom
    vertical-align: bottom !important
</style>
